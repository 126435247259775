import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import styles from "./featured-home.module.css";
import NavBar2 from "../NavBar2/NavBar2";
import Basket from "../Cart/Basket";
import StorageManager from "../../services/frontend/StorageManager";
import { CART_KEY } from "../../services/frontend/StorageKeys";
import { initialCart } from "../../services/ObjectsInitialValues";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useRouter } from "next/router";
import { publicServerEndpoint } from "../../services/helper";
import useStoreProducts from "../../hooks/useStoreProducts";
import FeaturedCollectionWithProd from "./FeaturedCollectionWithProd";

const FeaturedHome = ({
  store,
  updateHomeActive,
  heroMedia,
  heroMediaImages,
  heroMediaType,
  heroTitle,
}) => {
  const [cart, setCart] = useLocalStorage(CART_KEY, initialCart);
  const [showCart, setShowCart] = useState(false);
  const handleShowCart = (val = false) => {
    setShowCart(val);
  };
  const router = useRouter();
  const [selectedCollection, setSelectedCollection] = useState(null);

  useEffect(() => {
    //console.log('shubham:');
    //fetch the featured collection here. this collection will
    //display products on the home page below hero media
    const fetchCollections = async () => {
      try {
        let url = `${publicServerEndpoint}/store/${store.id}/collectionsv2`;
        const response = await fetch(url);
        const data = await response.json();

        console.log("Fetched Collections for Store ID:", store.id, data);
        if (data && data.length > 0) {
          setSelectedCollection(""); //data[0].name); // Adjust based on your data structure
        }
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchCollections();
  }, [store.id]);

  const {
    data: productsData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    error,
  } = useStoreProducts(store.id, selectedCollection, 10);

  useEffect(() => {
    if (productsData) {
      console.log("Fetched Products:", productsData);
    }
  }, [productsData]);

  return (
    <div>
      <div
        className={
          heroMediaType == "image" ? styles.heroForImages : styles.hero
        }
      >
        <Basket
          setRefresh={() => {}}
          isBasketOpen={showCart}
          setCart={setCart}
          cartData={cart}
          StorageManager={StorageManager}
          CART_KEY={CART_KEY}
          handleShowCart={handleShowCart}
        />
        <NavBar2
          cartActive={true}
          hideInAdvance={false}
          homeActive={true}
          storeName={heroTitle}
          handleShowCart={handleShowCart}
        />
        {heroMediaType == "image" ? (
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            className={styles.carouselImageContainer}
            onClickItem={(index) => router.push("/shop")}
          >
            {heroMediaImages.map((imageUrl, index) => (
              <div key={index}>
                <img
                  src={imageUrl}
                  alt={`Image ${index + 1}`}
                  className={styles.carouselImage}
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <ReactPlayer
            url={heroMedia}
            playing
            loop
            muted
            playsinline
            width="100%"
            height="100%"
            className={styles.backgroundVideo}
          />
        )}
      </div>
      <div className={styles.homeDetailsContainer}>
        <div
          onClick={() => updateHomeActive(false)}
          className={styles.homeDetailsButton}
        >
          {"SHOP NOW"}
        </div>
      </div>
      {console.log("shubham productsData:", productsData)}
      {          
        productsData && (
          <FeaturedCollectionWithProd 
            collectionName={selectedCollection || "Best Sellers"} 
            productsData={productsData?.pages.flatMap((page) => page.products)} 
          />
        )
      }
    </div>
  );
};

export default FeaturedHome;
