import React from "react";
import styles from "./product-list.module.css";
import StoreItem from "../StoreItem";

const FeaturedCollectionWithProd = ({ collectionName, productsData }) => {
  return (
    <div className={styles.productList}>
      <h2 className={styles.collectionName}>{collectionName}</h2>
      <div className={styles.horizontalList}>
        {productsData.map((product) => (
          <StoreItem key={product.id} product={product} pageSize={productsData.length} />
        ))}
      </div>
    </div>
  );
};

export default FeaturedCollectionWithProd;