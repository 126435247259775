import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NavBar from '../Navbar';
import useLocalStorage from '../../hooks/useLocalStorage';
import { CART_KEY } from '../../services/frontend/StorageKeys';
import { initialCart } from '../../services/ObjectsInitialValues';
import Basket from '../Cart/Basket';
import StorageManager from '../../services/frontend/StorageManager';
import LoaderComponent from '../Loader';
import StoreCollections from '../StoreCollections';
import StoreItem from '../StoreItem';
import EmptyStore from '../EmptyStore';
import Error500Page from '../../pages/500';
import Loader from 'react-loader-spinner';
import styles from "./collection-home.module.css";
import { useInView } from 'react-intersection-observer';
import useCollection from '../../hooks/useCollection';
import useStoreProducts from '../../hooks/useStoreProducts';
import { useRouter } from 'next/router';
import { QueryClient, useQueryClient } from 'react-query';
import {useScroll} from './ScrollPositionProvider';


export const CollectionHome = ({ store }) => {
  // const { data: collections, isLoading: isCollectionLoading, error: collectionError } = useCollection(store.id);
  const router = useRouter();

  const { collection: queryCollection } = router.query;
  console.log("queryCollection", queryCollection)
  const [selectedCollection, setSelectedCollection] = useState("all products");
  const [pageSize, setPageSize] = useState(20);
  const { ref, inView } = useInView();

  const {scrollPosition, updateScrollPosition} = useScroll();

  const {
    data: productData,
    isLoading: isProductsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error: productError,
  } = useStoreProducts(store.id, selectedCollection, pageSize);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        console.log('Tab is hidden');
      } else {
        console.log('Tab is visible');
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Save scroll position before navigating away
  useEffect(() => {
    const handleRouteChange = () => {
      console.log("Saving scroll position:", window.scrollY);
      updateScrollPosition(window.scrollY);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, updateScrollPosition]);


  useEffect(() => {
    //console.log("scrollPosition before", scrollPosition);
    //console.log("isProductsLoading ", isProductsLoading);
    if(!isProductsLoading && scrollPosition > 0){
      console.log("Restoring scroll position to:", scrollPosition);
      window.scrollTo(0, scrollPosition);
      updateScrollPosition(0);
    }
  }, [isProductsLoading, scrollPosition, updateScrollPosition]);


  const {
    data: collectionsData,
    isLoading: isCollectionsLoading,
    error: collectionsError,
  } = useCollection(store.id);
  const products = productData?.pages.flatMap((page) => page.products) || [];
  const collections = collectionsData?.data || [];

  const [cart, setCart] = useLocalStorage(CART_KEY, initialCart);
  const [showCart, setShowCart] = useState(false);
  const [collectionsHeight, setCollectionsHeight] = useState(0);
  // const selectedProducts = useMemo(() => {
  //   return products.get(selectedCollection)
  // }, [products, selectedCollection]);

  const handleShowCart = useCallback((boolVal = false) => {
    setShowCart(boolVal);
  }, []);


  const handleCollectionHeight = useCallback((collectionHeight) => {
    setCollectionsHeight(collectionHeight);
  }, []);

  useEffect(() => {
    const lastPageSize = sessionStorage.getItem('store-products-last-size');
    if(!lastPageSize) return
    const parsedPageSize = parseInt(lastPageSize, 10)
    if (parsedPageSize) {
      setPageSize(parseInt(lastPageSize, 10))
    }
  }, []);

  useEffect(() => {
    if (queryCollection && queryCollection !== selectedCollection) {
      setSelectedCollection(queryCollection);
    }
  }, [queryCollection]);
  const queryClient = useQueryClient()
  useEffect(() => {
    const cachedData = queryClient.getQueryData(['storeProducts', store.id, selectedCollection]);
    //console.log(`Cached data for [storeProducts, ${store.id}, ${selectedCollection}]:`, cachedData);
  }, [queryClient, store.id, selectedCollection, inView]);

  const setFilter = useCallback((collection) => {
    //console.log("selected collection", selectedCollection)
    //console.log("collection", collection)
    setSelectedCollection(collection);
      router.push({
        pathname: router.pathname,
        query: { ...router.query, collection },
      }, undefined, { shallow: true });

  },[router]);
 
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

   if (isCollectionsLoading || isProductsLoading) return <LoaderComponent />;
  if (collectionsError || productError) return <Error500Page />;
  return (
    <div className={styles.store}>
      <Basket
        setRefresh={() => { }}
        isBasketOpen={showCart}
        setCart={setCart}
        cartData={cart}
        StorageManager={StorageManager}
        CART_KEY={CART_KEY}
        handleShowCart={handleShowCart}
      />
      <NavBar
        cartActive={cart.products.length > 0}
        handleShowCart={handleShowCart}
        hideInAdvance={false}
        homeActive={false}
        store={store}
        storeName={store?.storeName || ''}
      />
      {
        collections && collections.length > 0 && (
          <StoreCollections
            collections={collections}
            setCollectionsHeight={handleCollectionHeight}
            setFilter={setFilter}
            selectedCollection={selectedCollection}
          />
        )
      }
      {products.length > 0 ? (
        <div className={styles.storeItems}
        style={{ paddingTop: `${collectionsHeight + 16}px` }}
         >
          {products.map((product, index) => (
            <div key={product.id} ref={index === products.length - 1 ? ref : null}>
              <StoreItem product={product} pageSize={index+20 > 20 ? index+20 : 20} />
            </div>
          ))}
        </div>
      ) : (
        <EmptyStore store={store} />
      )}
      {/* Loader for next page */}
      {isFetchingNextPage && (
        <div className={styles.productLoader}>
          <Loader type="Oval" color="#ccc" height={40} width={40} />
        </div>
      )}
      {/* {!selectedProducts || (selectedProducts.length === 0 && isProductLoading) ? (
        <div className={styles.firstTimeCollectionProductLoader}>
          <Loader
            type="Oval"
            color="#ccc"
            height={40}
            width={40}
          />
        </div>)
        :
        (
          <div
            className={selectedProducts.length > 0 ? styles.storeItems : ''}
            style={{ paddingTop: `${collectionsHeight + 16}px` }}
          >
            {selectedProducts.length > 0 ? (
              selectedProducts.map((product, index) => {
                if (selectedProducts.length === index + 1) {
                  return (
                    <div ref={lastProductRef} key={product.id}>
                      <StoreItem product={product} />
                    </div>
                  );
                }
                return <StoreItem product={product} key={product.id} />;
              })
            ) : (
              <EmptyStore store={store} />
            )}
          </div>
        )
      } */}
      {/* {selectedProducts && selectedProducts.length !== 0 && isProductLoading && (
        <div className={styles.productLoader}>
          <Loader
            type="Oval"
            color="#ccc"
            height={40}
            width={40}
          />
        </div>
      )} */}
    </div>
  );
};