import React, { createContext, useState, useContext, useCallback } from 'react';
import { useEffect } from 'react';

// Create the context
const ScrollContext = createContext();

// Create a provider component
export const ScrollProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const updateScrollPosition = useCallback((position) => {
    setScrollPosition(position);
  }, []);

  return (
    <ScrollContext.Provider value={{ scrollPosition, updateScrollPosition }}>
      {children}
    </ScrollContext.Provider>
  );
};

// Custom hook to use the ScrollContext
export const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const savedPosition = localStorage.getItem('scrollPosition');
    if (savedPosition) {
      setScrollPosition(parseInt(savedPosition, 10));
    }
  }, []);

  const updateScrollPosition = (position) => {
    localStorage.setItem('scrollPosition', position);
    setScrollPosition(position);
  };

  return { scrollPosition, updateScrollPosition };
};