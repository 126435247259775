import React, { useState, useEffect } from "react";
import styles from "./store-item.module.css";
import Image from "next/image";
import LinesEllipsis from "react-lines-ellipsis";
import { useRouter } from "next/router";
import Link from "next/link";
import { useScroll } from "./Home/ScrollPositionProvider";


import { LazyLoadImage } from 'react-lazy-load-image-component';

import Lottie from "lottie-react";
import * as animationData from './shimmer.json'

const StoreItem = ({ product, pageSize }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fallbackSet, setFallbackSet] = useState(false);
  const [imageName, setImageName] = useState(null);
  const router = useRouter()
  const { updateScrollPosition} = useScroll();
  const getProductPrice = (price, mrp='') => {
    if(!price) return;
    if(mrp) {
      let strikeOutMRP = <span className={styles.strikeOutText}>{`${String.fromCharCode(0x20b9)}${mrp}`}</span>;
      return <>{`${String.fromCharCode(0x20b9)}${price}`} {strikeOutMRP}</>;
    } else {
      return `${String.fromCharCode(0x20b9)}${product.price}`
    }
  }
  
  if (!product) return "";
  useEffect(() => {
    const { parse, basename } = require('path');
    
    const urlPath =  (product.productPhotos && product.productPhotos[0]) ? new URL(product.productPhotos[0]).pathname : "";

    const extractedImageName = basename(parse(urlPath).name);
    
    setImageName(extractedImageName);
  }, [product.productPhotos]);



  const checkStock = (product) => {
    let stockAvailable = false;
    if (product.variantOptions && product.variantOptions.length) {
      product.variantOptions.forEach(variant => {
        if (variant?.availableStock > 0) {
          stockAvailable = true;
        }
      });
    } else {
      return product.availableStock;
    }
    return stockAvailable;
  }

  return (
    <div
      href={`/product/${product.id}`}
      className={styles.storeItem}
      onClick={() => {
        updateScrollPosition(window.scrollY);

        console.log("pageSize", pageSize)
        sessionStorage.setItem('store-products-last-size', pageSize);
        window.location.href = `/product/${product.id}`;
      }}
    >
      <div>
        <div className={styles.thumbnail}>
          {product && product?.productPhotos[0] && (
            <div className={styles.image_container}>
              {!checkStock(product) && (
                <div className={styles.item_overlay}>
                  <p>SOLD</p>
                </div>
              )}
              {
                !imageLoaded
                ? <div className={styles.productImg}>
                  <Lottie
                    style={{
                      borderRadius: '10px'
                    }}
                    animationData = {animationData}            
                    loop={true}
                    options={{                  
                      autoplay: true,                    
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                   />
                </div> : ''
              }
              <LazyLoadImage
                style={!imageLoaded ? {
                  visibility: 'hidden',
                  height: '0px !important',
                  width: '0px !important',
                  zIndex: 1,
                  position: 'absolute'
                } : {}}
                onLoad={() => {                            
                  setImageLoaded(true)
                }}
                onError={(e) => {
                  if (!fallbackSet) {
                    // Handle image load error by setting a fallback URL
                    e.target.src = `${product?.productPhotos[0]}`;
                    setFallbackSet(true);
                  }
                }}
                className={styles.productImg}
                src={imageName ? `https://dm2buy-drop-resized-gga4c6azekgcgngp.z02.azurefd.net/dm2buy/${imageName}.jpg` : ''}
                unoptimized="true"
                // height={204}
                // width={204}
                objectfit="cover"
                alt= {product.name}
                // priority
              />
            </div>
          )}
        </div>

        <div className={styles.details}>
          <div className={styles.title}>
            {product && (
              <LinesEllipsis
                className={styles.storeNameText}
                text={`${product.name}`}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            )}
          </div>
          <div className={styles.price}>
            {product && getProductPrice(product.price, product.mrp)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreItem;