import React, { useEffect } from "react";
import styles from "./collection-filter.module.css";

const CollectionFilter = ({ collection, selectedCollection, setFilter }) => {
  const isSelected = collection === selectedCollection;

  const handleClick = () => {
    if (!isSelected) {
      setFilter(collection);
    }
  };

  return (
    <div
      className={isSelected ? styles.selectedFilter : styles.collectionFilter}
      onClick={handleClick}
    >
      {collection}
    </div>
  );
};

export default CollectionFilter;